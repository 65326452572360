// Match the following values:
// 100
// 100.0
// 100.00

export default {
  getMessage (field, args) {
    return 'Please enter a valid number - no spaces or symbols.'
  },
  validate (value, args) {
    if (value === undefined || value === null) {
      return false
    }

    return !!String(value).match(/^([0-9]{1,})([0-9]{1,})*?(\.)?(\.[0-9]{1,})?$/gm)
  }
}
