<template>
  <div :class="baseFieldId">
    <contextual-help
      v-if="show"
      :help="question.help"
      :question="question.label"
    />
    <fieldset
      v-if="show"
      :class="{ 'question--invalid': errors.has(formInputName) }"
      class="grid-x question"
    >
      <div
        v-if="showLabel()"
        class="cell"
      >
        <label :for="baseFieldId">
          <legend v-html="question.label" />
          <div v-html="question.note" />
        </label>
      </div>
      <div
        :class="`medium-${width}`"
        class="cell"
      >
        <input
          :id="baseFieldId"
          v-validate="`${validation}`"
          data-vv-validate-on="blur"
          :aria-describedby="'error-message' + baseFieldId"
          :name="formInputName"
          :value="value"
          type="text"
          autocomplete="off"
          class="focus"
          @input="$emit('input', $event.target.value)"
        >
      </div>
      <div class="cell">
        <span
          v-show="errors.has(formInputName)"
          :id="'error-message' + baseFieldId"
          role="alert"
          class="input-error"
        >
          {{ errors.first(formInputName) }}
        </span>
      </div>
    </fieldset>
    <div v-else>
      <input
        :name="formInputName"
        type="hidden"
        value=""
      >
    </div>
  </div>
</template>

<script>
import ComponentMixin from '../../mixins/component-mixin.js'
import ContextualHelp from '../contextual-help.vue'

export default {
  components: {
    ContextualHelp
  },
  mixins: [ComponentMixin],
  inject: ['$validator'],
  props: {
    width: {
      default: 4
    },
    validation: {
      type: String,
      default: 'numeric'
    }
  }
}
</script>
