<template>
  <div
    v-if="help"
    class="contextual-help"
  >
    <div
      :id="modalId"
      class="reveal"
    >
      <div class="reveal-content">
        <div class="reveal-header">
          <div class="grid-x grid-padding-x grid-padding-y">
            <div class="cell auto">
              <h5>What does this question mean?</h5>
            </div>
            <div class="cell shrink">
              <button
                data-close
                aria-label="Close modal"
                type="button"
                class="reveal-close"
              >
                <span aria-hidden="true">
                  <i class="fa fa-times" />
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="contextual-help__content reveal-body">
          <div
            class="contextual-help__content__short-text"
            v-html="shortText"
          />

          <div v-if="longText">
            <a
              v-if="!showMore"
              href="#"
              class="contextual-help__content__toggle"
              @click.prevent="showMore = !showMore"
            >
              Show more
              <i class="fa fa-plus" />
            </a>
            <a
              v-if="showMore"
              href="#"
              class="contextual-help__content__toggle"
              @click.prevent="showMore = !showMore"
            >
              Show less
              <i class="fa fa-minus" />
            </a>

            <div
              v-if="showMore"
              class="contextual-help__content__long-text"
              v-html="longText"
            />
          </div>
        </div>
      </div>
    </div>

    <a
      ref="helpButton"
      href="#"
      title="What does this question mean?"
      :aria-label="`Help with '${question}' question`"
      role="button"
      @click.prevent="openModal()"
    >
      Help<i class="fa fa-icon fa-question-circle" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    help: Object,
    question: String
  },
  data () {
    return {
      showMore: false,
      modal: null
    }
  },
  computed: {
    shortText (val) {
      if (this.help) {
        return this.help.short
      }
    },
    longText (val) {
      if (this.help) {
        return this.help.long
      }
    },
    modalId () {
      return `modal_${btoa(Math.random()).substr(5, 10)}`
    },
    modalDocumentId () {
      return `#${this.modalId}`
    }
  },
  watch: {
    showMore (val) {
      $(window).trigger('resize')
    }
  },
  mounted () {
    this.modal = new Foundation.Reveal($(this.modalDocumentId), { deepLink: true, updateHistory: true })
    this.modal.$element.on('closed.zf.reveal', this.closeModal)
  },
  methods: {
    openModal () {
      const me = this

      me.showMore = false

      setTimeout(function () {
        me.modal.open()
      }, 100)
    },
    closeModal () {
      this.modal.close()
      this.$refs.helpButton.focus()
    }
  }
}
</script>
