import JSONLogic from 'json-logic-js'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    value: {},
    question: {
      type: Object,
      required: true
    },
    profile: Object,
    options: Object
  },
  computed: {
    formInputName () {
      const parts = this.field.split('.')
      let name = parts.shift()

      for (const part of parts) {
        name += `[${part}]`
      }

      return name
    },
    baseFieldId () {
      return this.field.split('.').join('_')
    },
    show () {
      if (process.env.NODE_ENV !== 'production') {
        // Check for the debug flag
        // to determine if we wanted to show all components
        const url = new URL(window.location.href)
        const debugParams = url.searchParams.get('debug')

        if (debugParams === 'true') {
          return true
        }
      }

      if (this.question.rule !== 'null' && this.question.rule !== null) {
        return JSONLogic.apply(this.question.rule, this.profile)
      } else {
        return true
      }
    }
  },
  watch: {
    show (val) {
      if (!val) {
        this.$emit('input', null)

        if (this.options && this.options.skip_null_value) {
          this.$emit('input', this.value)
        }
      }
    }
  },
  mounted () {
    if (this.value == null) {
      this.$emit('input', this.question.form_default)
    }
  },
  methods: {
    showLabel () {
      if (this.options && this.options.label === false) {
        return false
      } else {
        return true
      }
    }
  }
}
