const validationEl = document.querySelector('.validation-messages')
const validationMessages = JSON.parse(validationEl.dataset.messages)

function errorMessageLookup (field, rule) {
  const fieldName = field.slice(field.lastIndexOf('[') + 1, -1)

  if (validationMessages[fieldName]) {
    return validationMessages[fieldName][rule]
  } else {
    return null
  }
}

export default {
  en: {
    messages: {
      required: (field, args) => {
        return `* ${errorMessageLookup(field, 'required')}` || `* ${field} field is required.`
      },
      numeric: (field, args) => {
        return errorMessageLookup(field, 'numeric') || `${field} field may only contain numeric characters.`
      },
      min_value: (field, args) => {
        return errorMessageLookup(field, 'min_value') || `${field} field must be ${args[0]} or less`
      },
      max_value: (field, args) => {
        return errorMessageLookup(field, 'max_value') || `${field} field must be ${args[0]} or less`
      },
      min: (field, args) => {
        return errorMessageLookup(field, 'min') || `${field} field must be at least ${args[0]} characters`
      },
      max: (field, args) => {
        return errorMessageLookup(field, 'max') || `${field} may not be greater than ${args[0]} characters.`
      }
    }
  }
}
