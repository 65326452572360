import Vue from 'vue/dist/vue.esm'
import ValidationMixin from '../../profiles/mixins/validation-mixin'

import NumberControl from '../../profiles/components/controls/number-control.vue'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('.simple_form')

  if (element !== null) {
    const profile = JSON.parse(element.dataset.profile)

    new Vue({
      el: element,
      components: {
        NumberControl
      },
      mixins: [ValidationMixin],
      data () {
        return { profile }
      }
    })
  }
})
