import Vue from 'vue/dist/vue.esm'
import VeeValidate, { Validator } from 'vee-validate'

// Rules
import currencyRule from './rules/currency'
import dictionary from './rules/dictionary'

Vue.use(VeeValidate)

export default {
  methods: {
    enableSubmit () {
      setTimeout(function () {
        document.querySelector('[name="commit"]').removeAttribute('disabled')
      }, 200)
    },
    focusOnError () {
      const firstFieldNameWithError = Object.keys(this.errors.collect())[0]
      const firstFieldWithError = document.querySelector(`[name="${firstFieldNameWithError}"]`)

      firstFieldWithError.focus()
      firstFieldWithError.scrollIntoView({
        behavior: 'smooth'
      })
    },
    validateBeforeSubmit (e) {
      e.preventDefault()

      this.$validator.validateAll().then((result) => {
        if (result) {
          document.querySelector('.simple_form').submit()
        } else {
          this.enableSubmit()
          this.focusOnError()
        }
      }).catch(() => {
        this.enableSubmit()
        this.focusOnError()
        return false
      })
    }
  },
  created () {
    Validator.extend('currency', currencyRule)
    Validator.localize(dictionary)
  }
}
